// This file is used to inject environment variables into the app

let WALTER_HOST: string;
let ADD_IN_HOST: string;
let WORD_OAUTH_CLIENT_ID: string;

// In test, development and preview environments we read the environment variable values from .env files such
// as .env.development.local and .env.test
if (import.meta.env.DEV || ["preview", "test"].includes(import.meta.env.MODE)) {
  WALTER_HOST = import.meta.env.VITE_WALTER_HOST;
  ADD_IN_HOST = import.meta.env.VITE_HOST;
  WORD_OAUTH_CLIENT_ID = import.meta.env.VITE_WORD_OAUTH_CLIENT_ID;
}
// For other environments (staging and production)
// The $ values are replaced by environment variables after the build on CI and before uploading the
// build output of this file to an S3 bucket.
// The environment variables that replace the placeholder for staging and production are set
// in the CI pipeline in part from .buildkite/plugins/deploy-metadata/hooks/environment and also
// from AWS secrets manager.
// The variable replacement is done by .buildkite/scripts/sub_env_vars_in_build
else {
  WALTER_HOST = "$VITE_WALTER_HOST";
  ADD_IN_HOST = "$VITE_HOST";
  WORD_OAUTH_CLIENT_ID = "$VITE_WORD_OAUTH_CLIENT_ID";
}

export { WALTER_HOST, ADD_IN_HOST, WORD_OAUTH_CLIENT_ID };
